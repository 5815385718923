.Lynx_navbar{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    text-transform: uppercase !important;
    box-sizing: border-box;
    background-color: 
    #436573 !important;

}
.Lynx_docs{
  background-color: 
  #3B3C40 !important;
  color: #DCECF2;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
    font-weight: 700 !important;;
    text-transform: uppercase !important;
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.Lynx_terms{
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding-top: 1.5rem;
  box-sizing: border-box;
}
.text-white {
    color: white;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
    font-weight: 700 !important;;
    text-transform: uppercase !important;
  }
  .text-lynx {
    color:  #436573 !important;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
    font-weight: 700 !important;;
    text-transform: uppercase !important;
  }
  .lynx_opacity_box {
    margin: 30px;
    background-color: black;
 
    opacity: 0.6;
  }
  
  .lynx_opacity_text {
    margin: 5%;
    font-weight: bold;
    color: white;   
     font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
   
    text-transform: uppercase !important;
  }

  .Lynx_menubar{
    padding-top: calc(6rem + 74px);
    padding-bottom: 6rem;
    color: white;
    background-color: 
    #63A5BF !important;
  }
  .sidebar {
    padding-top: calc(6rem + 74px);
    padding-bottom: 6rem;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 58px 0 0; /* Height of navbar */
    height: 100% !important;
    width: 240px;
    z-index: 600;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    }
.Lynx_footer{
  color: white;
  
  padding-top: 1.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
  box-sizing: border-box;
  background-color: 
  #436573 !important;
  position: relative;
  bottom: 0; 

  
}
   .lynx_darkcards{
    background-color: 
    black!important;
    color: white;
    border: 2px solid white !important;
  padding: 10px !important;
  border-radius: 25px !important;
  max-width: 100%
   }
   .lynx_darkcards_noborder{
    background-color: 
    black!important;
    color: white;
   
  padding: 10px !important;
 
  max-width: 100%
   }
   .lynx-background { 
    background: url(/public/lynx2.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   
  }
  .fun-background { 
    background: url(/public/Fun.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   
  }
  .cascading-right {
    margin-right: -50px;
  }

  @media (max-width: 991.98px) {
    .cascading-right {
      margin-right: 0;
    }
  }
.img_bootstrap{
 
  backdrop-filter: blur(30px);
}
.lynx_img{
  max-width: 80%;
  
}


@media (max-width: 600px) {
  .lynx_m_img { width: 100% !important; } /* perhaps 60% fits better */
  .lynx_m_img { flex-grow:5 !important; } /* change other classes accordingly */

  
}


.smsgpt{
  
  color:#FD6621!important;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-weight: bold !important;;
}
.lynx_orangecards{
  background-color: 
  #FD6621!important;
  color: white;
  border: 2px solid white !important;
padding: 10px !important;
border-radius: 25px !important;
max-width: 100%
 }
 .lynx_gptcards{
  background-color: 
  black!important;
    color: white!important;
  border: 2px solid #FD6621!important;
padding: 10px !important;
border-radius: 25px !important;
max-width: 100%
 }
 .text-black {
  color: white;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
  font-weight: 700 !important;;
  text-transform: uppercase !important;
}