.home-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #264653; /* Dark teal for text */
    background-color: #f4a261; /* Lighter coral for background */
  }
  
  .home-page img {
    width: 80%;
    max-width: 400px; /* Adjust maximum width as needed */
  }
  
  .fancy-font {
    font-family: 'Poppins', sans-serif; /* Modern font */
    font-size: 2.5rem; /* Larger font size */
  }
  
  .fancy-text {
    font-size: 1.5rem; /* Larger font size */
  }
  
  .fancy-button {
    background-color: #e76f51; /* Coral for button */
    border-color: #e76f51; /* Coral for button border */
    font-size: 1.2rem; /* Larger font size */
  }
  
  .fancy-button:hover {
    background-color: #ba4d3a; /* Darken button color on hover */
    border-color: #ba4d3a; /* Darken button border on hover */
  }
  .quiz-page {
    background-color: #f4a261; /* Background color */
    color: royalblue; /* Text color */
  }
  
  /* Modal styling */
  .modal-content {
    background-color: #f4a261; /* Modal background color */
    color: #264653; /* Modal text color */
  }
  
  .modal-header {
    border-bottom: 1px solid #264653; /* Modal header border color */
  }
  
  .modal-footer {
    border-top: 1px solid #264653; /* Modal footer border color */
  }
  
  .modal-title {
    color: #264653; /* Modal title text color */
  }